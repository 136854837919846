import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Calculator from "../components/calculator"
import Cta from "../components/cta"

export const query = graphql`
  query FreeToolsQuery {
    strapiFreetools {
      ctasTopOfPage {
        id
        description
        title
        buttonText
        link
        color
        buttonPingAnimation
        fixedId
        externalLink
        visible
      }
    }
  }
`

export default function FreeTools({ data, location }) {
  const seo = {
    metaTitle: "Free Tools",
    metaDescription: "A collection of free tools for employment disputes",
    url: location.pathname,
  }
  return (
    <Layout seo={seo}>
      <div className="container mx-auto px-4 mt-12 lg:px-12 mb-12">
        <h1 className="font-sans font-bold text-4xl md:text-5xl lg:text-6xl mb-10">
          Free Tools
        </h1>
        <div className="grid grid-cols-12 sm:gap-12 items-start">
          <div className="col-span-12 md:col-span-6">
            {data.strapiFreetools.ctasTopOfPage.map((value, index) => {
              if (value?.visible)
                return (
                  <Cta ctaData={value} key={index} className="mb-12 md:mb-12" />
                )
            })}
          </div>

          <Calculator />
        </div>
      </div>
    </Layout>
  )
}
