import React from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker-cssmodules.css"

import {
  format,
  addMonths,
  subDays,
  parseISO,
  differenceInDays,
  addDays,
  isValid,
} from "date-fns"

class Calculator extends React.Component {
  constructor(props) {
    super(props)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)

    this.state = {
      terminationDate: null,
      text: "",
      dayA: null,
      dayB: null,
      timeLimit: null,
      confirmed: false,
    }
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    const terminationDate = this.state.terminationDate
    const dayA = this.state.dayA
    const dayB = this.state.dayB
    const dayBPlus1Month = addMonths(dayB, 1)
    let timeLimit = ""
    let stopTheClock = ""

    try {
      this.setState({
        timeLimit: "",
        text: "",
      })

      if (terminationDate && dayA && dayB && dayA < dayB) {
        stopTheClock = addDays(
          subDays(addMonths(terminationDate, 3), 1),
          differenceInDays(dayB, dayA)
        )

        if (stopTheClock >= dayA && stopTheClock <= dayBPlus1Month) {
          timeLimit = dayBPlus1Month
        } else {
          timeLimit = stopTheClock
        }

        if (dayA < terminationDate) {
          stopTheClock = addDays(
            subDays(addMonths(terminationDate, 3), 1),
            differenceInDays(dayB, terminationDate)
          )
          timeLimit = stopTheClock
        }

        if (dayA < terminationDate && dayB < terminationDate) {
          timeLimit = subDays(addMonths(terminationDate, 3), 1)
        }

        if (dayA > subDays(addMonths(terminationDate, 3), 1)) {
          this.setState({
            timeLimit: subDays(addMonths(terminationDate, 3), 1),
            text: " (Acas submitted too late)",
          })
        }
      } else {
        this.setState({
          text:
            "Please make sure to enter all dates correctly and in DD/MM/YYYY format",
        })
      }

      if (isValid(timeLimit)) {
        this.setState({
          timeLimit: timeLimit,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  handleDateChange(event, name) {
    this.setState({
      [name]: event,
    })
  }

  render() {
    return (
      <div className="col-span-12 sm:col-span-6 mb-12">
        <div
          className="rounded-md p-8 shadow-xl h-full 
         bg-white
        border-gray-50 border"
        >
          <h2 className="text-3xl font-bold mb-4">
            How long do I have to make a claim?
          </h2>
          <p className="mb-8">
            Calculate your deadline for submitting your tribunal claim for your
            dismissal. Fill out all fields below and press 'Calculate'.
          </p>
          <form onSubmit={this.handleSubmit}>
            <div className="block mb-4">
              <label className="font-bold">Termination Date</label>
              <br />
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={this.state.terminationDate}
                onChange={date =>
                  this.handleDateChange(date, "terminationDate")
                }
                disabledKeyboardNavigation
                placeholderText="DD/MM/YYYY"
              />
            </div>

            <div className="block mb-4">
              <label className="font-bold">
                Date of receipt by Acas of the EC notification (Day A)
              </label>
              <br />
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={this.state.dayA}
                onChange={date => this.handleDateChange(date, "dayA")}
                disabledKeyboardNavigation
                placeholderText="DD/MM/YYYY"
              />
            </div>

            <div className="block mb-8">
              <label className="font-bold">
                Date of issue by Acas of this Certificate (Day B)
              </label>
              <br />
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={this.state.dayB}
                onChange={date => this.handleDateChange(date, "dayB")}
                disabledKeyboardNavigation
                placeholderText="DD/MM/YYYY"
              />
            </div>

            <div className="block mb-8">
              <label className="b-contain">
                <span className="ml-4">
                  Please tick the box to indicate that you understand that this
                  date is a guideline only. You should always aim to file your
                  claim form as soon as possible and should seek the advice of a
                  solicitor if you are unsure.
                </span>
                <input
                  name="confirmed"
                  type="checkbox"
                  checked={this.state.confirmed}
                  onChange={this.handleInputChange}
                />
                <div class="b-input"></div>
              </label>
            </div>
            <button
              type="submit"
              value="Submit"
              className="disabled:opacity-20 mb-4 border-black w-full sm:w-auto font-bold text-lg text-center border-4  rounded-md py-2 px-12 transition duration-500 ease select-none hover:text-white hover:bg-pink-800 focus:outline-none hover:border-pink-800 focus:shadow-outline self-start"
              disabled={!this.state.confirmed}
            >
              Calculate
            </button>

            {(!!this.state.timeLimit || !!this.state.text) && (
              <div className="block text-2xl">
                Time Limit:{" "}
                <strong>
                  {!!this.state.timeLimit &&
                    format(this.state?.timeLimit, "dd/MM/yyyy")}
                  {!!this.state.text && this.state?.text}
                </strong>
              </div>
            )}
          </form>
        </div>
      </div>
    )
  }
}
export default Calculator
